body {
  margin: 0;
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Inter";
  /*TODO : change here  "Can be any text" */
  src: local("Inter-Regular"),
    url("./assets/Fonts/Inter-Regular.ttf") format("truetype");
  /*change font here */
}

.ant-table-row {
  cursor: pointer;
}

.ant-card-meta-title {
  font-size: 30px !important;
}

.ant-card-meta-description {
  font-size: 20px !important;
}